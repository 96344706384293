/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import Slider from 'react-slick';
import { Row, Col, ArrowLink, NewsBox, showError, useWindowSize } from 'components';
import { getNews } from 'services';
import { filterModel, getTranslations } from 'utils';
import { wrap } from './styles';

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const LatestNews = ({ titleKey, noSlider, categoryId }) => {
  const { getText, language } = useTranslations();
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const [news, setNews] = useState();
  const isMobile = windowWidth.width <= 767;

  useEffect(() => {
    language && fetchNews();
  }, [language?.code]);

  const fetchNews = async () => {
    const filter = filterModel({
      ...(categoryId && { categoryId: `categoryId="${categoryId}"` }),
      ...(language?.code === 'zh' && { chinaRestricted: 'chinaRestricted=false' }),
      isActive: 'isActive=true',
      isVitaOnly: 'isVitaOnly=false',
    });

    const [res, err] = await getNews({
      perPage: 3,
      sort: categoryId ? '@random' : '-createdAt',
      filter,
      fields: `id,collectionId,image,title,description,createdAt,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
      skipTotal: true,
    });
    if (err) return showError(err?.data);
    setNews(res);
  };

  return (
    <div css={wrap}>
      <Row container>
        <Col sm={12}>
          <div className="header">
            <h4 className="title">{getText(titleKey)}</h4>
            <ArrowLink title={getText('seeAll')} linkTo={'/news'} />
          </div>
        </Col>
      </Row>
      <Row container>
        {!isMobile || noSlider ? (
          <>
            {news?.items?.map((el) => (
              <Col md={4} key={el?.id} className="news-box-item">
                <NewsBox data={el} />
              </Col>
            ))}
          </>
        ) : (
          <Col sm={12}>
            <Slider {...settings}>
              {news?.items?.map((el) => (
                <NewsBox data={el} key={el?.id} />
              ))}
            </Slider>
          </Col>
        )}
      </Row>
    </div>
  );
};

LatestNews.propTypes = {
  titleKey: PropTypes.string,
  categoryId: PropTypes.string,
  noSlider: PropTypes.bool,
};

export default LatestNews;
