import { Link } from 'react-router-dom';
import { useTranslations } from '@veraio/strank';
import { Icon } from 'components';
import { footerContainer } from './styles';

const Footer = () => {
  const getYear = new Date().getFullYear();
  const { getText } = useTranslations();

  return (
    <div css={footerContainer}>
      <div className="all-rights">
        <p>
          © {getYear} {getText('allRightsReserved')}.
        </p>
      </div>
      <div className="footer-links">
        <Link to="/terms-and-conditions">{getText('termsConditions')}</Link>
      </div>
      <div className="footer-links">
        <Link to="/privacy-policy"> {getText('privacyPolicy')}</Link>
      </div>
      <div className="footer-social">
        <a href="https://www.facebook.com/oneecosystemofficial" target="_blank" rel="noreferrer">
          <Icon iconName="fab fa-facebook" />
        </a>
        <a href="https://www.instagram.com/oneecosystem_official/" target="_blank" rel="noreferrer">
          <Icon iconName="fab fa-instagram" />
        </a>
        <a href="https://www.youtube.com/channel/UCfEwiRg2zYs6T6oRZQrF5WA" target="_blank" rel="noreferrer">
          <Icon iconName="fab fa-youtube" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
