export const header = (bgImage) => (theme) => ({
  marginBottom: '4em',
  '& > h2': {
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '1em 0',
  },
  '& > .top-news': {
    backgroundColor: theme.primaryDark,
    padding: '2.5em',
    '& .image-holder': {
      backgroundColor: theme.primaryDark,
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      aspectRatio: '16 / 9',
      width: '100%',
    },
    '& .content-holder': {
      '& .news-date': {
        fontSize: '0.8em',
        marginBottom: '0.8em',
      },
      '& .news-title': {
        textTransform: 'uppercase',
        marginBottom: '0.5em',
      },
      '& .news-text': {
        marginBottom: '1.5em',
      },
    },
  },
  '@media (max-width: 991px)': {
    '& > .top-news': {
      '& .image-holder': {
        marginBottom: '2em',
      },
    },
  },
  '@media (max-width: 767px)': {
    padding: '0 2em',
  },
});
