import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Row, Col, EventsList, EventTypeBadge, showError } from 'components';
import { getEvents } from 'services';
import { getImagePath, getTranslations, getTranslationData, eventDateFormat } from 'utils';
import { header } from './styles';

const Events = () => {
  const { getText, language } = useTranslations();
  const [mainEvent, setMainEvent] = useState();
  const { month, day } = eventDateFormat(mainEvent?.startDate, mainEvent?.endDate);

  useEffect(() => {
    language && fetchEvents();
  }, [language?.code]);

  const fetchEvents = async () => {
    const [res, err] = await getEvents({
      filter: '(isMain=true && isActive=true)',
      fields: `id,collectionId,image,title,description,createdBy,startDate,endDate,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);
    if (res?.totalItems > 0) setMainEvent(res?.items?.at(0));
  };

  return (
    <Row container>
      <Col>
        <section css={header(getImagePath(mainEvent))}>
          <h2>{getText('oneEcosystemEvents')}</h2>
          <Button type="secondary" leftIcon={{ iconName: 'las la-calendar', size: 20 }} linkTo={'/events-calendar'}>
            View calendar
          </Button>
          <div className="top-events">
            <Row horizontalGap={32} align="center">
              <Col lg={6} xl={5}>
                <div className="image-holder" />
              </Col>
              <Col lg={6} xl={7}>
                {mainEvent && (
                  <div className="content-holder">
                    <p className="events-date">{`${month}, ${day}`}</p>
                    <EventTypeBadge createdBy={mainEvent?.createdBy} />
                    <h4 className="events-title">{getTranslationData(mainEvent, 'title', language?.code)}</h4>
                    <p className="events-text">{getTranslationData(mainEvent, 'description', language?.code)}</p>
                    <Button linkTo={`/events/${mainEvent?.id}`} type="secondary">
                      {getText('readMore')}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </section>
        <EventsList />
      </Col>
    </Row>
  );
};

export default Events;
