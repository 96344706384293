export const footerContainer = (theme) => ({
  backgroundColor: theme.primaryDark,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  padding: '2em 0',
  '& > .all-rights > p': {
    fontSize: '0.9rem',
    margin: '0 1em',
  },
  '& > .footer-links > a': {
    fontSize: '0.9rem',
    color: theme.secondaryDark,
    margin: '0 1em',
    '&:hover': {
      color: theme.secondary,
    },
  },

  '& > .footer-social > a': {
    color: theme.secondaryDark,
    margin: '0 0.5em',
    '&:nth-of-type(1):hover': {
      color: '#1877F2',
    },
    '&:nth-of-type(2):hover': {
      color: '#C13584',
    },
    '&:nth-of-type(3):hover': {
      color: '#ff0033',
    },
  },
  '@media (max-width: 767px)': {
    '& .all-rights ': {
      flex: '0 0 100%',
      textAlign: 'center',
      marginBottom: '0.5em',
    },
    '& .footer-links ': {
      textAlign: 'right',
      marginBottom: '1em',
      '&:last-of-type': {
        textAlign: 'left',
      },
    },
    '& > .footer-social ': {
      flex: '0 0 100%',
      textAlign: 'center',
      '& > a': {
        margin: '0 1em',
      },
    },
  },
});
