import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { Button, Row, Col, NewsList, showError } from 'components';
import { getNews } from 'services';
import { filterModel, dateFormat, getImagePath, getTranslations, getTranslationData } from 'utils';
import { header } from './styles';

const News = () => {
  const { getText, language } = useTranslations();
  const [mainNews, setMainNews] = useState();

  useEffect(() => {
    language && fetchNews();
  }, [language?.code]);

  const fetchNews = async () => {
    const filter = filterModel({
      ...(language?.code === 'zh' && { chinaRestricted: 'chinaRestricted=false' }),
      isActive: 'isActive=true',
      isMain: 'isMain=true',
      isVitaOnly: 'isVitaOnly=false',
    });
    const [res, err] = await getNews({
      filter,
      fields: `id,collectionId,image,title,description,createdAt,${getTranslations(
        ['title', 'description'],
        language?.code,
      )}`,
    });
    if (err) return showError(err?.data);
    if (res?.totalItems > 0) setMainNews(res?.items?.at(0));
  };

  return (
    <>
      <Row container>
        <Col>
          <section css={header(getImagePath(mainNews))}>
            <h2>{getText('oneEcosystemNews')}</h2>
            <div className="top-news">
              <Row horizontalGap={32} align="center">
                <Col lg={6} xl={5}>
                  <div className="image-holder" />
                </Col>
                <Col lg={6} xl={7}>
                  {mainNews && (
                    <div className="content-holder">
                      <p className="news-date">{dateFormat(mainNews?.createdAt)}</p>
                      <h4 className="news-title">{getTranslationData(mainNews, 'title', language?.code)}</h4>
                      <p className="news-text">{getTranslationData(mainNews, 'description', language?.code)}</p>
                      <Button linkTo={`/news/${mainNews?.id}`} type="secondary">
                        {getText('readMore')}
                      </Button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </section>
          <NewsList />
        </Col>
      </Row>
    </>
  );
};

export default News;
